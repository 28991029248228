import { inject } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Navigation, Router } from '@angular/router';

export class NavigationFallbackService {
  private navigationIds: (string | number)[] = [];
  private router = inject(Router);
  private location = inject(Location);
  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigation(this.router.getCurrentNavigation(), event.id);
      }
    });
  }

  handleNavigation(
    navigation: Navigation | null,
    navigationId: number | string,
  ) {
    const replaceUrl = navigation?.extras?.replaceUrl;
    if (!replaceUrl || this.navigationIds.length === 0) {
      this.navigationIds.push(navigationId);
    } else {
      this.navigationIds[this.navigationIds.length - 1] = navigationId;
    }
  }

  back(): void {
    this.navigationIds.pop();
    if (this.navigationIds.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
