import { effect } from '@angular/core';
import {
  signalStoreFeature,
  withState,
  withMethods,
  patchState,
  withHooks,
  getState,
} from '@ngrx/signals';

export type RememberState = { remember: boolean };

export interface RememberConfig {
  storageKey: string;
  always?: boolean;
}

export function withRemembering(config: string | RememberConfig) {
  if (typeof config === 'string') {
    config = {
      storageKey: config,
    } as RememberConfig;
  }

  return signalStoreFeature(
    withState<RememberState>({ remember: false }),

    withMethods((store) => ({
      updateRemember(remember: boolean): void {
        patchState(store, () => ({ remember: remember }));
      },
    })),

    withHooks({
      onInit(store) {
        try {
          const storedState = localStorage.getItem(config.storageKey);
          if (storedState) {
            // parse state
            const parsedState = JSON.parse(storedState);

            if (!config.always) {
              parsedState.remember = true;
            }
            patchState(store, () => parsedState);
          }
        } catch {
          localStorage.removeItem(config.storageKey);
        }

        effect(() => {
          const state = getState(store);
          if (state.remember || config.always) {
            if (config.always) {
              // next line is for extracting remember out, therefore it would be unused
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { remember, ...state2 } = state;
              localStorage.setItem(config.storageKey, JSON.stringify(state2));
            } else {
              localStorage.setItem(config.storageKey, JSON.stringify(state));
            }
          } else {
            localStorage.removeItem(config.storageKey);
          }
        });
      },
    }),
  );
}
