import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DevStoreMonitorStore } from './dev-store-monitor.store';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IconComponent } from '@cca/ui';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cca-dev-store-monitor',
  standalone: true,
  imports: [MatSlideToggleModule, MatButtonModule, IconComponent],
  templateUrl: './dev-store-monitor.component.html',
  styleUrl: './dev-store-monitor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevStoreMonitorComponent {
  devStoreMonitor = inject(DevStoreMonitorStore);
}
