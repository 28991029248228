import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DevSettingStore } from './dev-settings.store';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cca-dev-settings',
  standalone: true,
  imports: [MatSlideToggleModule, MatButtonModule],
  templateUrl: './dev-settings.component.html',
  styleUrl: './dev-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevSettingsComponent {
  settings = inject(DevSettingStore);
}
