<div
  annoying
  class="pointer-events-auto my-4 flex w-96 cursor-pointer gap-4 rounded-xl border border-cca-neutrals-400 bg-cca-neutrals-100 p-4"
>
  <cca-icon
    class="inline-table h-6 w-6 rounded-full bg-cca-purple-50 p-2 text-center align-middle text-2xl leading-none text-cca-purple-500"
    icon="invoice"
  ></cca-icon>
  <div class="flex w-full flex-col gap-1" (click)="onClick()">
    <h3 class="text-base font-bold text-cca-neutrals-800">
      {{ notification.title }}
    </h3>
    <span class="text-xs text-cca-neutrals-500">{{
      notification.sentAt | ccaDate: 'f'
    }}</span>
  </div>
  <button
    type="button"
    (click)="onClose($event)"
    class="pointer h-5 w-5 cursor-pointer place-items-center text-xl leading-4 text-cca-neutrals-500"
  >
    <cca-icon icon="circle-xmark"></cca-icon>
  </button>
</div>
