import { InjectionToken } from '@angular/core';
import { NavigationFallbackService } from './navigation-fallback';
import { NavigationNativeService } from './navigation-native';

export interface NavigationService {
  back(): void;
}

export const NavigationServiceToken = new InjectionToken<NavigationService>(
  'NavigationService',
  {
    factory() {
      // try if the navigation api is accessible
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (navigation && navigation?.currentEntry) {
          return new NavigationNativeService();
        }
      } catch (e) {
        // on error, we can just ignore it and go for the fallback
      }

      return new NavigationFallbackService();
    },
  },
);
