import {
  Injectable,
  OnDestroy,
  computed,
  inject,
  isDevMode,
} from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import {
  Observable,
  tap,
  switchMap,
  EMPTY,
  withLatestFrom,
  finalize,
  Subscription,
  mergeMap,
  skip,
} from 'rxjs';
// import { CCANotificationDateGroup } from '@cca/common';
import { AuthenticationFacade } from '@cca-common/authentication';
import {
  DateGroup,
  WebNotificationService,
  WebNotificationViewModel,
} from '@cca-infra/notification-service/v1';
import { NotificationsPanelComponent } from './notifications-panel';
import { SidePanelService } from '@cca-common/core';

export interface NotificationsState {
  allNotifications: {
    index: number;
    items: WebNotificationViewModel[];
    totalItems: number;
  };
  unreadNotifications: {
    index: number;
    items: WebNotificationViewModel[];
    totalItems: number;
  };
  unreadNotificationCount: number;
  newNotifications: number;
  loading: boolean;
  firstLoad: boolean;
  error: unknown;
}

export enum NotificationStatus {
  Unread = 3,
  Read = 2,
  All = 1,
}

function isCCANotificationDateGroup(value: unknown): value is DateGroup {
  return !isNaN(Number(value));
}

export const initialNotificationsState: NotificationsState = {
  allNotifications: {
    index: -1,
    items: [],
    totalItems: Infinity,
  },
  unreadNotifications: {
    index: -1,
    items: [],
    totalItems: Infinity,
  },
  unreadNotificationCount: 0,
  loading: false,
  firstLoad: true,
  error: null,
  newNotifications: 0,
};

const CCANotificationDateGroupValues = Object.values(DateGroup).filter((v) =>
  isCCANotificationDateGroup(v),
) as DateGroup[];

const NOTIFICATION_PAGE_SIZE = 20;

@Injectable({
  providedIn: 'root',
})
export class NotificationsStore
  extends ComponentStore<NotificationsState>
  implements OnDestroy
{
  private _userSubscription: Subscription | null = null;
  private readonly sidePanelService = inject(SidePanelService);
  private readonly notificationService = inject(WebNotificationService);
  private readonly authenticationFacade = inject(AuthenticationFacade);

  constructor() {
    super(initialNotificationsState);

    if (
      isDevMode() &&
      inject(NotificationsStore, { optional: true, skipSelf: true })
    ) {
      throw Error('NotificationStore is already provided');
    }

    /**
     * Reset state if user becomes null ( when logged out )
     */
    this._userSubscription = inject(AuthenticationFacade)
      .user$.pipe(skip(1))
      .subscribe((user) => {
        if (!user) {
          this.patchState(initialNotificationsState);
        }
      });
  }

  /**
   * Selectors
   */
  readonly loading = this.selectSignal((state) => {
    return !!state.loading;
  });

  readonly firstLoad = this.selectSignal((state) => {
    return !!state.firstLoad;
  });

  readonly error = this.selectSignal((state) => {
    return state.error;
  });

  readonly notifications = this.selectSignal((state) => {
    return CCANotificationDateGroupValues.map((value) => {
      return {
        notifications:
          state.allNotifications.items?.filter(
            (n) => n.dateGroupValue === value,
          ) ?? [],
        value: value,
        isPanelOpened: true,
      };
    });
  });

  readonly unreadNotifications = this.selectSignal((state) => {
    return CCANotificationDateGroupValues.map((value) => {
      return {
        notifications:
          state.unreadNotifications.items?.filter(
            (n) => n.dateGroupValue === value && !n.isRead,
          ) ?? [],
        value: value,
        isPanelOpened: true,
      };
    });
  });

  readonly totalUnreadNotifications = this.selectSignal(
    (state) => state.unreadNotifications.totalItems,
  );
  readonly totalNotifications = this.selectSignal(
    (state) => state.allNotifications.totalItems,
  );

  readonly unreadNotificationsCount = this.selectSignal((state) => {
    return state.unreadNotificationCount;
  });

  readonly newNotifications = this.selectSignal((state) => {
    return state.newNotifications;
  });

  readonly showLoadMoreNotification = this.selectSignal((state) => {
    return (
      state.allNotifications.items.length < state.allNotifications.totalItems
    );
  });

  readonly showLoadMoreUnreadNotification = this.selectSignal((state) => {
    return (
      state.unreadNotifications.items.length <
      state.unreadNotifications.totalItems
    );
  });

  /**
   * Updaters
   */
  readonly setLoading = this.updater(
    (state: NotificationsState, loading: boolean): NotificationsState => {
      return {
        ...state,
        loading: loading,
        firstLoad: loading && state.firstLoad,
      };
    },
  );

  readonly setError = this.updater(
    (state: NotificationsState, error: unknown): NotificationsState => {
      return {
        ...state,
        error: error,
      };
    },
  );

  readonly incrementNewNotification = this.updater(
    (state: NotificationsState): NotificationsState => {
      return {
        ...state,
        newNotifications: state.newNotifications + 1,
        unreadNotificationCount: state.unreadNotificationCount + 1,
      };
    },
  );

  readonly resetNewNotification = this.updater(
    (state: NotificationsState): NotificationsState => {
      return {
        ...state,
        newNotifications: 0,
      };
    },
  );

  /**
   * Effects
   */

  readonly markAsRead = this.effect(
    (origin$: Observable<WebNotificationViewModel>) =>
      origin$.pipe(
        switchMap((notification) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (notification as any).isRead = true;

          return this.notificationService.markAsRead(notification.id).pipe(
            tapResponse(
              (unreadNotificationCount) => {
                const all = this.get((s) => s.allNotifications.items);
                const unread = this.get((s) => s.unreadNotifications.items);

                const allIndex = all.indexOf(notification);
                const unreadIndex = all.indexOf(notification);

                if (allIndex >= 0) {
                  this.patchState({
                    allNotifications: {
                      ...this.get((s) => s.allNotifications),
                      items: [
                        ...all.slice(0, allIndex),
                        notification,
                        ...all.slice(allIndex + 1),
                      ],
                    },
                  });
                }

                if (unreadIndex >= 0) {
                  this.patchState({
                    unreadNotifications: {
                      ...this.get((s) => s.allNotifications),
                      items: [
                        ...unread.slice(0, unreadIndex),
                        notification,
                        ...unread.slice(unreadIndex + 1),
                      ],
                    },
                  });
                }

                this.patchState({
                  unreadNotificationCount: unreadNotificationCount,
                });
              },
              (err) => {
                console.error(err);
              },
            ),
          );
        }),
      ),
  );

  readonly markAllAsRead = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      withLatestFrom(this.authenticationFacade.user$),
      switchMap((results) => {
        const user = results[1];
        if (!user) {
          return EMPTY;
        }

        return this.notificationService.markAllAsRead(user?.userId).pipe(
          tapResponse(
            () => {
              // patch all notifications to have isRead: true
              this.patchState({
                allNotifications: {
                  ...this.get((s) => s.allNotifications),
                  items: this.get((s) => s.allNotifications.items).map((n) => {
                    return {
                      ...n,
                      isRead: true,
                    };
                  }),
                },
                unreadNotifications: {
                  ...this.get((s) => s.unreadNotifications),
                  items: this.get((s) => s.unreadNotifications.items).map(
                    (n) => {
                      return {
                        ...n,
                        isRead: true,
                      };
                    },
                  ),
                },
                unreadNotificationCount: 0,
              });
            },
            (err) => {
              console.error(err);
            },
          ),
        );
      }),
    ),
  );

  readonly markAsUnread = this.effect(
    (origin$: Observable<WebNotificationViewModel>) =>
      origin$.pipe(
        switchMap((notification) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (notification as any).isRead = false;

          return this.notificationService.markAsUnread(notification.id).pipe(
            tapResponse(
              (unreadNotificationCount) => {
                const all = this.get((s) => s.allNotifications.items);
                const unread = this.get((s) => s.unreadNotifications.items);

                const allIndex = all.indexOf(notification);
                const unreadIndex = all.indexOf(notification);

                if (allIndex >= 0) {
                  this.patchState({
                    allNotifications: {
                      ...this.get((s) => s.allNotifications),
                      items: [
                        ...all.slice(0, allIndex),
                        notification,
                        ...all.slice(allIndex + 1),
                      ],
                    },
                  });
                }

                if (unreadIndex >= 0) {
                  this.patchState({
                    unreadNotifications: {
                      ...this.get((s) => s.allNotifications),
                      items: [
                        ...unread.slice(0, unreadIndex),
                        notification,
                        ...unread.slice(unreadIndex + 1),
                      ],
                    },
                  });
                }

                this.patchState({
                  unreadNotificationCount: unreadNotificationCount,
                });
              },
              (err) => {
                console.error(err);
              },
            ),
          );
        }),
      ),
  );

  readonly getUnreadNotifications = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      withLatestFrom(this.authenticationFacade.user$),
      switchMap((results) => {
        const user = results[1];
        if (!user) {
          return EMPTY;
        }
        return this.notificationService.countUnread(user.userId).pipe(
          tapResponse(
            (notificationCount) => {
              this.patchState({
                unreadNotificationCount: notificationCount,
              });
            },
            (err) => {
              console.error(err);
            },
          ),
        );
      }),
    ),
  );

  readonly getNextNotifications = this.effect(
    (origin$: Observable<NotificationStatus>) =>
      origin$.pipe(
        tap(() => {
          this.setError(false);
        }),
        withLatestFrom(this.authenticationFacade.user$),
        mergeMap((results) => {
          const user = results[1];
          const status = results[0];
          if (!user) {
            return EMPTY;
          }

          let index = -1;
          if (status === NotificationStatus.All) {
            index = this.get((s) => s.allNotifications.index);
          } else {
            index = this.get((s) => s.unreadNotifications.index);
          }
          index++;

          this.setLoading(true);
          return this.notificationService
            .notificationPagination(
              {
                page: {
                  pageIndex: index,
                  pageSize: NOTIFICATION_PAGE_SIZE,
                },

                // make sure we send both a direction and active column if we have a direction
                // without direction we should revert to basic sort ( empty string let backend decide )
                // see https://dev.azure.com/chaincode-development/ChainCargo/_workitems/edit/9987/
                sort: {
                  active: '',
                  direction: 'asc',
                },
                filters: [],
              },
              {
                userId: user.userId,
                status: status,
              },
            )
            .pipe(
              tapResponse(
                (res) => {
                  this.setLoading(false);

                  if (status === NotificationStatus.All) {
                    const newItems = [
                      ...this.get((s) => s.allNotifications.items),
                      ...res.items,
                    ];
                    const uniqueNotifications = newItems
                      .reduce<WebNotificationViewModel[]>(
                        (accumulator, current) => {
                          if (
                            !accumulator.find((item) => item.id === current.id)
                          ) {
                            accumulator.push(current);
                          }
                          return accumulator;
                        },
                        [],
                      )
                      .sort((a, b) => b.sentAt - a.sentAt);

                    this.patchState({
                      allNotifications: {
                        index: index,
                        items: uniqueNotifications,
                        totalItems: res.totalItems,
                      },
                    });
                  } else {
                    const newItems = [
                      ...this.get((s) => s.unreadNotifications.items),
                      ...res.items,
                    ];
                    const uniqueNotifications = newItems
                      .reduce<WebNotificationViewModel[]>(
                        (accumulator, current) => {
                          if (
                            !accumulator.find((item) => item.id === current.id)
                          ) {
                            accumulator.push(current);
                          }
                          return accumulator;
                        },
                        [],
                      )
                      .sort((a, b) => b.sentAt - a.sentAt);

                    this.patchState({
                      unreadNotifications: {
                        index: index,
                        items: uniqueNotifications,
                        totalItems: res.totalItems,
                      },
                    });
                  }
                },
                (err) => {
                  this.setLoading(false);
                  this.setError(err);
                },
              ),
              finalize(() => this.setLoading(false)),
            );
        }),
      ),
  );

  readonly getNewNotifications = this.effect(
    (origin$: Observable<NotificationStatus>) =>
      origin$.pipe(
        tap(() => {
          this.setError(false);
        }),
        withLatestFrom(this.authenticationFacade.user$),
        mergeMap((results) => {
          const user = results[1];
          const status = results[0];
          if (!user) {
            return EMPTY;
          }

          const newNotificationCount = this.newNotifications();

          this.setLoading(true);
          return this.notificationService
            .notificationPagination(
              {
                page: {
                  pageIndex: 0,
                  pageSize: newNotificationCount + NOTIFICATION_PAGE_SIZE,
                },

                // make sure we send both a direction and active column if we have a direction
                // without direction we should revert to basic sort ( empty string let backend decide )
                // see https://dev.azure.com/chaincode-development/ChainCargo/_workitems/edit/9987/
                sort: {
                  active: '',
                  direction: 'asc',
                },
                filters: [],
              },
              {
                userId: user.userId,
                status: status,
              },
            )
            .pipe(
              tapResponse(
                (res) => {
                  this.setLoading(false);

                  if (status === NotificationStatus.All) {
                    const newItems = [
                      ...this.get((s) => s.allNotifications.items),
                      ...res.items,
                    ];
                    const uniqueNotifications = newItems
                      .reduce<WebNotificationViewModel[]>(
                        (accumulator, current) => {
                          if (
                            !accumulator.find((item) => item.id === current.id)
                          ) {
                            accumulator.push(current);
                          }
                          return accumulator;
                        },
                        [],
                      )
                      .sort((a, b) => b.sentAt - a.sentAt);

                    const indexIncrement = Math.floor(
                      (uniqueNotifications.length -
                        this.get((s) => s.allNotifications.items.length)) /
                        NOTIFICATION_PAGE_SIZE,
                    );

                    this.patchState({
                      allNotifications: {
                        index:
                          this.get((s) => s.allNotifications.index) +
                          indexIncrement,
                        items: uniqueNotifications,
                        totalItems: res.totalItems,
                      },
                    });
                  } else {
                    const newItems = [
                      ...this.get((s) => s.unreadNotifications.items),
                      ...res.items,
                    ];
                    const uniqueNotifications = newItems
                      .reduce<WebNotificationViewModel[]>(
                        (accumulator, current) => {
                          if (
                            !accumulator.find((item) => item.id === current.id)
                          ) {
                            accumulator.push(current);
                          }
                          return accumulator;
                        },
                        [],
                      )
                      .sort((a, b) => b.sentAt - a.sentAt);

                    const indexIncrement = Math.floor(
                      (uniqueNotifications.length -
                        this.get((s) => s.unreadNotifications.items.length)) /
                        NOTIFICATION_PAGE_SIZE,
                    );

                    this.patchState({
                      unreadNotifications: {
                        index:
                          this.get((s) => s.allNotifications.index) +
                          indexIncrement,
                        items: uniqueNotifications,
                        totalItems: res.totalItems,
                      },
                    });
                  }
                },
                (err) => {
                  this.setLoading(false);
                  this.setError(err);
                },
              ),
              finalize(() => this.setLoading(false)),
            );
        }),
      ),
  );

  closePanel() {
    this.sidePanelService.close();
  }

  openPanel() {
    this.sidePanelService.open(NotificationsPanelComponent);
  }

  isPanelOpened = computed(
    () =>
      this.sidePanelService.content()?.component ===
      NotificationsPanelComponent,
  );

  override ngOnDestroy(): void {
    this._userSubscription?.unsubscribe();
    this._userSubscription = null;
  }
}
