import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IconComponent } from '@cca/ui';
import { MatButtonModule } from '@angular/material/button';
import { SidePanelService } from '@cca-common/core';
import { DevMenuComponent } from '../dev-menu/dev-menu.component';

@Component({
  selector: 'cca-dev-menu-toggle',
  standalone: true,
  imports: [IconComponent, MatButtonModule],
  templateUrl: './dev-menu-toggle.component.html',
  styleUrl: './dev-menu-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevMenuToggleComponent {
  sidePanel = inject(SidePanelService);

  openDevMenu() {
    this.sidePanel.open(DevMenuComponent);
  }
}
