import { DOCUMENT } from '@angular/common';
import { inject, RendererFactory2 } from '@angular/core';
import {
  signalStoreFeature,
  withMethods,
  withState,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';

export type DarkModeState = { darkMode: boolean };

export function withDarkMode() {
  return signalStoreFeature(
    withState<DarkModeState>({ darkMode: false }),

    withMethods(
      (
        store,
        rendererFactory = inject(RendererFactory2),
        document = inject(DOCUMENT),
      ) => ({
        updateDarkMode(darkMode: boolean): void {
          patchState(store, () => ({ darkMode: darkMode }));
        },
        _applyDarkMode: rxMethod<boolean>(
          pipe(
            tap((enabled) => {
              const renderer = rendererFactory.createRenderer(null, null);
              const htmlElement = document.body.parentElement;
              if (!htmlElement) {
                return;
              }

              if (enabled) {
                renderer.setStyle(
                  htmlElement,
                  'filter',
                  'invert(0.95) hue-rotate(180deg',
                );
              } else {
                renderer.setStyle(htmlElement, 'filter', 'initial');
              }
            }),
          ),
        ),
      }),
    ),

    withHooks({
      onInit(store) {
        store._applyDarkMode(store.darkMode);
      },
    }),
  );
}
