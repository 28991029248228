<div
  class="pr-4 pt-14"
  [@notification]="displayedNotifications.length"
  (animationend)="tick()"
>
  @for (
    notification of displayedNotifications;
    track notification.id;
    let index = $index
  ) {
    <cca-notification
      (closeNotification)="closeNotification(index)"
      (openNotification)="openNotificationPanel()"
      [notification]="notification"
    ></cca-notification>
  }
</div>
