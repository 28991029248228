import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { IconComponent } from '@cca/ui';
import { TranslocoModule } from '@jsverse/transloco';
import { MessageBannerService } from './message-banner.service';

@Component({
  standalone: true,
  selector: 'cca-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrl: './message-banner.component.scss',
  imports: [CommonModule, IconComponent, TranslocoModule],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageBannerComponent {
  private messageBannerService = inject(MessageBannerService);

  protected messages = this.messageBannerService.messages;
  protected currentIndex = 0;

  protected previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  protected next() {
    if (this.currentIndex < this.messages().length - 1) {
      this.currentIndex++;
    }
  }
}
