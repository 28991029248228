import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import {
  TranslocoModule,
  provideTranslocoScope,
  TranslocoService,
} from '@jsverse/transloco';
import { CdkDatePipe, RouterFacade } from '@cca-common/cdk';
import { IconComponent } from '@cca/ui';
import { NotificationsStore } from '../../notifications.store';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  WebNotificationViewModel,
  WebPushMessageSubType,
} from '@cca-infra/notification-service/v1';
import { NgClass } from '@angular/common';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';

type NotificationTranslationKeys =
  | 'ArrivedAtActionSuccess'
  | 'ArrivedAtActionFailed';
export type NotificationTranslations = Record<
  NotificationTranslationKeys,
  string
>;
export enum CCANotificationEventType {
  PickupEta = 'PickupEta',
  UserMentioned = 'UserMentioned',
  ConversationReply = 'ConversationReply',
  LaneRequestReminder = 'LanesRequestReminder',
  RequestOfferMTShipperRate = 'RequestOfferMTShipperRate',
  TaskRequestAssignOperator = 'TaskRequestAssignOperator',
  TenderCarrierBidReceived = 'TenderCarrierBidReceived',
  SystemAutomationSuccess = 'SystemAutomationSuccess',
  SystemAutomationFailed = 'SystemAutomationFailed',
}

@Component({
  selector: 'cca-notification-tile',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatBadgeModule,
    TranslocoModule,
    CdkDatePipe,
    IconComponent,
    MatTooltipModule,
    NgClass,
  ],
  templateUrl: './notification-tile.component.html',
  styleUrls: ['./notification-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('notifications')],
})
export class NotificationTileComponent {
  router = inject(RouterFacade);
  notificationsStore = inject(NotificationsStore);
  transloco = inject(TranslocoService);

  @Input({ required: true }) notification!: WebNotificationViewModel;

  onNotificationClick() {
    switch (this.notification.eventType) {
      case CCANotificationEventType.UserMentioned:
      case CCANotificationEventType.ConversationReply:
        {
          this.handleConversationRouting();
        }
        break;
      case CCANotificationEventType.TaskRequestAssignOperator:
      case CCANotificationEventType.RequestOfferMTShipperRate:
        {
          this.router.navigate(['taskboard'], {
            queryParams: {
              taskId: this.getTaskId(),
            },
          });
        }
        break;
      case CCANotificationEventType.TenderCarrierBidReceived:
        {
          this.handleTenderCarrierBidNotification();
        }
        break;
      default:
        this.handleNotification();
        break;
    }

    this.notificationsStore.closePanel();
    this.notificationsStore.markAsRead(this.notification);
  }

  handleTenderCarrierBidNotification() {
    const tenderRequestType = this.getTenderRequestType();
    if (tenderRequestType === 'ManagedTrans') {
      this.router.navigateByUrl(
        `/tender/mt/detail/${this.getReferenceNumber()}/shipment/${this.getTenderRequestRowId()}/info`,
      );
    } else {
      this.router.navigate(
        [`/tender/adhoc/detail/${this.getReferenceNumber()}/info`],
        { queryParams: { requestIterationId: this.getTenderRequestRowId() } },
      );
    }
  }

  handleNotification() {
    const referenceNumber = this.getReferenceNumber();
    if (!referenceNumber) {
      return;
    }

    const entityType = this.getEntityType();
    if (!entityType) {
      return;
    }

    switch (entityType) {
      case 'Order':
        {
          this.router.navigateByUrl(`/order/detail/${referenceNumber}/info`);
        }
        break;
      case 'Request':
        {
          this.router.navigateByUrl(
            `/request/transport/detail/${referenceNumber}/info`,
          );
        }
        break;
      case 'Lane':
        {
          this.router.navigateByUrl(`/lane/detail/${referenceNumber}/info`);
        }
        break;
      case 'LaneRequest':
        {
          this.router.navigateByUrl(`/request/lane/detail/${referenceNumber}`);
        }
        break;
      default:
    }
  }

  handleConversationRouting() {
    const referenceNumber = this.getReferenceNumber();
    if (!referenceNumber) {
      return;
    }

    const entityType = this.getEntityType();
    if (!entityType) {
      return;
    }

    switch (entityType) {
      case 'Order':
        {
          this.router.navigateByUrl(
            `/order/detail/${referenceNumber}/communication`,
          );
        }
        break;
      case 'Request':
        {
          this.router.navigateByUrl(
            `/request/transport/detail/${referenceNumber}/communication`,
          );
        }
        break;
      case 'Lane':
        {
          this.router.navigateByUrl(
            `/lane/detail/${referenceNumber}/communication`,
          );
        }
        break;
      default:
    }
    return;
  }

  getReferenceNumber() {
    return (
      this.notification?.metaData?.find((x) => x.key === 'REFERENCENUMBER')
        ?.value ?? null
    );
  }

  getEntityType() {
    return (
      this.notification?.metaData?.find((x) => x.key === 'ENTITYTYPE')?.value ??
      null
    );
  }

  getTenderRequestType() {
    return (
      this.notification?.metaData?.find((x) => x.key === 'TENDERREQUESTTYPE')
        ?.value ?? null
    );
  }

  getTaskId() {
    return (
      this.notification?.metaData?.find((x) => x.key === 'TASKID')?.value ??
      null
    );
  }

  getTenderRequestRowId() {
    return (
      this.notification?.metaData?.find((x) => x.key === 'TENDERREQUESTROWID')
        ?.value ?? null
    );
  }

  getAutomationType() {
    return (
      this.notification?.metaData?.find((x) => x.key === 'AUTOMATIONTYPE')
        ?.value ?? null
    );
  }

  getText() {
    switch (this.notification.webPushMessageSubType) {
      case WebPushMessageSubType.SystemAutomationSuccess:
        if (this.getAutomationType() === 'ArrivedAtAction') {
          return t('notifications.Automation.Success.ArrivedAtAction');
        }
        return this.notification.text;
      case WebPushMessageSubType.SystemAutomationFailed:
        if (this.getAutomationType() === 'ArrivedAtAction') {
          return t('notifications.Automation.Failed.ArrivedAtAction');
        }
        return this.notification.text;
      default:
        return this.notification.text;
    }
  }

  getNotificationIconType() {
    switch (this.notification.webPushMessageSubType) {
      case WebPushMessageSubType.SystemAutomationFailed:
        return 'warning';
      case WebPushMessageSubType.TaskCreationRequest:
        return 'no-more-task';
      case WebPushMessageSubType.TenderRelated:
        return 'messages-dollar';
      default:
        return 'invoice';
    }
  }

  getNotificationIconColor() {
    switch (this.notification.webPushMessageSubType) {
      case WebPushMessageSubType.SystemAutomationFailed:
        return 'bg-cca-red-50 text-cca-red-500';
      case WebPushMessageSubType.SystemAutomationSuccess:
      case WebPushMessageSubType.UserMentioned:
        return 'bg-cca-purple-50 text-cca-green-500';
      default:
        return 'bg-cca-purple-50 text-cca-purple-500';
    }
  }
}
