@if (messages().length > 0) {
  <div
    class="grid h-12 w-full grid-cols-7 content-center"
    [class]="messages()[currentIndex].flavor"
  >
    <div class="pl-5">
      @if (currentIndex !== 0) {
        <button class="w-2">
          <cca-icon
            class="cursor-pointer text-xl"
            icon="chevron-left"
            (click)="previous()"
          ></cca-icon>
        </button>
      }
    </div>
    <div class="col-span-5 self-center justify-self-center">
      <span>
        {{ messages()[currentIndex].description | transloco }}
        <a
          [href]="messages()[currentIndex].link"
          class="ml-3 text-cca-green-500 underline"
        >
          {{ messages()[currentIndex].linkText | transloco }}
        </a>
      </span>
    </div>
    <div class="justify-self-end pr-9">
      @if (currentIndex !== messages().length - 1) {
        <button class="w-2">
          <cca-icon
            class="cursor-pointer text-xl"
            icon="chevron-right"
            (click)="next()"
          ></cca-icon>
        </button>
      }
    </div>
  </div>
}
