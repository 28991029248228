import { inject, computed, Injector, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap, merge, Observable } from 'rxjs';
import { DevStoreRegistryStore } from '../dev-tracking';
import { withRemembering } from '../dev-settings/features';

type MonitorState = { trackedStores: string[] };

function getValueFromSymbol(obj: unknown, symbol: symbol) {
  if (typeof obj === 'object' && obj && symbol in obj) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (obj as { [key: symbol]: any })[symbol];
  }
}

function getStoreSignal(store: unknown): Signal<unknown> {
  const [signalStateKey] = Object.getOwnPropertySymbols(store);
  if (!signalStateKey) {
    throw new Error('Cannot find State Signal');
  }

  return getValueFromSymbol(store, signalStateKey);
}

export const DevStoreMonitorStore = signalStore(
  { providedIn: 'root' },
  withState<MonitorState>({ trackedStores: [] }),
  withComputed((store) => {
    const registry = inject(DevStoreRegistryStore);
    return {
      availableStores: computed(() => Object.values(registry.stores())),
      entries: computed(() => {
        const availableStores = Object.keys(registry.stores());
        const trackedStores = store.trackedStores();

        return availableStores.map((storeName) => {
          return {
            name: storeName,
            tracked: trackedStores.includes(storeName),
          };
        });
      }),
    };
  }),
  withMethods(
    (
      store,
      registry = inject(DevStoreRegistryStore),
      injector = inject(Injector),
    ) => ({
      addTrackedStore(storeIdentifier: string): void {
        patchState(store, () => ({
          trackedStores: Array.from(
            new Set([...store.trackedStores(), storeIdentifier]),
          ),
        }));
      },

      removeTrackedStore(storeIdentifier: string): void {
        patchState(store, () => ({
          trackedStores: store
            .trackedStores()
            .filter((str) => str !== storeIdentifier),
        }));
      },

      clear(): void {
        patchState(store, () => ({
          trackedStores: [],
        }));
      },

      _watchStores: rxMethod<{ name: string; tracked: boolean }[]>(
        pipe(
          switchMap((entries) => {
            const storeMap = registry.stores();
            const obs: Observable<unknown>[] = [];
            for (const entry of entries) {
              if (entry.name in storeMap && entry.tracked) {
                const stateSource = storeMap[entry.name];
                const storeSignal = getStoreSignal(stateSource);

                obs.push(
                  toObservable(storeSignal, {
                    injector: injector,
                  }).pipe(
                    tap((state) => {
                      console.group?.(`Store ${entry.name}`);
                      console.log(state);
                      const d = new Date();
                      console.log(`${d.toString()}`);
                      console.groupEnd?.();
                    }),
                  ),
                );
              }
            }

            return merge(...obs);
          }),
        ),
      ),
    }),
  ),

  withHooks((store) => {
    return {
      onInit() {
        store._watchStores(store.entries);
      },
    };
  }),

  withRemembering({ storageKey: 'DEV-TRACKED-STORES', always: true }),
);
