import {
  trigger,
  transition,
  style,
  animate,
  query,
  group,
} from '@angular/animations';

export const notificationAnimation = trigger('notification', [
  transition(':increment', [
    group([
      query(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate(
          '500ms ease',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      query('cca-notification:not(:animating)', [
        style({ transform: 'translateY(-100%)' }),
        animate('500ms ease', style({ transform: 'translateY(0)' })),
      ]),
    ]),
  ]),
  transition(':decrement', [
    query(':leave', [
      style({ opacity: 1 }),
      animate('200ms ease', style({ opacity: 0 })),
    ]),
  ]),
]);
