import { signalStore } from '@ngrx/signals';
import {
  withAutoToggle,
  withDarkMode,
  withRemembering,
  withRouterTracing,
} from './features';

const DEV_STORAGE_KEY = 'DEV-SETTINGS';

export const DevSettingStore = signalStore(
  { providedIn: 'root' },
  withDarkMode(),
  withRouterTracing(),
  withRemembering(DEV_STORAGE_KEY),
  withAutoToggle(),
);
