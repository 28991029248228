import { ComponentRef, NgModule, NgZone, inject } from '@angular/core';
import { finalize, switchMap, takeUntil } from 'rxjs';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationOverlayComponent } from './notification-overlay';
import {
  refreshAccessTokenSuccess,
  authenticateSuccessAction,
  logoutComplete,
  refreshAccessTokenFailure,
} from '@cca-common/authentication';
import { Actions, ofType } from '@ngrx/effects';
import { NotificationViewModel } from '@cca-infra/notification-service/v1';
import { SignalrService } from '@cca-infra/core';

@NgModule({
  imports: [OverlayModule],
})
export class CommonNotificationsModule {
  overlay = inject(Overlay);
  ngZone = inject(NgZone);
  componentRef: ComponentRef<NotificationOverlayComponent>;

  constructor() {
    const positionStrategy = this.overlay.position().global().right('0');

    const overlayRef = this.overlay.create({
      disposeOnNavigation: false,
      positionStrategy: positionStrategy,
      direction: 'ltr',
      panelClass: 'pointer-events-none',
    });
    const userProfilePortal = new ComponentPortal(NotificationOverlayComponent);
    this.componentRef = overlayRef.attach(userProfilePortal);

    /**SIGNAL R */
    const signalR = inject(SignalrService);
    const actions = inject(Actions);
    actions
      .pipe(
        ofType(refreshAccessTokenSuccess, authenticateSuccessAction),
        switchMap(() => {
          // initiate connection
          const connection = signalR.startConnection(
            'notification',
            'notificationservicehub',
          );

          //subscribe to messages BE -> FE
          return connection.events$.pipe(
            takeUntil(
              actions.pipe(ofType(logoutComplete, refreshAccessTokenFailure)),
            ),
            finalize(() => {
              connection.close();
            }),
          );
        }),
      )
      .subscribe({
        next: (message) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let parsed: any;
          try {
            parsed = JSON.parse(message);
          } catch {
            return;
          }

          //placeholder
          this.ngZone.run(() => {
            this.componentRef.instance.addNotification(
              parsed as NotificationViewModel,
            );
          });
        },
        complete: () => {
          //placeholder
        },
        error: () => {
          //placeholder
        },
      });
  }
}
