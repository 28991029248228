import { signalStoreFeature, withHooks, type } from '@ngrx/signals';
import { RememberState } from './remember';
import { enableDevMenu } from '../../enable';

export function withAutoToggle() {
  return signalStoreFeature(
    { state: type<RememberState>() },
    withHooks({
      onInit(store) {
        if (store.remember()) {
          enableDevMenu();
        }
      },
    }),
  );
}
