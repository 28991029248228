import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { inject } from '@angular/core';

export class NavigationNativeService {
  private router = inject(Router);
  private location = inject(Location);

  back(): void {
    if (this.navigationIndex) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/', {
        replaceUrl: true,
      });
    }
  }

  get navigationIndex() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return navigation.currentEntry.index;
  }
}
