import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
} from '@angular/core';

import { notificationAnimation } from '../animation';
import { NotificationComponent } from './notification/notification.component';
import { provideTranslocoScope } from '@jsverse/transloco';
import { NotificationsStore } from '../notifications.store';
import { NotificationViewModel } from '@cca-infra/notification-service/v1';

@Component({
  selector: 'cca-notification-overlay',
  standalone: true,
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NotificationComponent],
  providers: [provideTranslocoScope('notifications')],
  animations: [notificationAnimation],
})
export class NotificationOverlayComponent {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly notificationStore = inject(NotificationsStore);
  readonly MAX_DISPLAYED_NOTIFICATIONS = 3;
  readonly MAX_DISPLAY_TIME = 10_000;

  notificationQueue: NotificationViewModel[] = [];
  displayedNotifications: NotificationViewModel[] = [];

  constructor() {
    effect(() => {
      if (this.notificationStore.isPanelOpened()) {
        this.clear();
        this.cdr.markForCheck();
      }
    });
  }

  clear() {
    this.notificationQueue = [];
    this.displayedNotifications = [];
  }

  addNotification(notification: NotificationViewModel) {
    if (this.notificationStore.isPanelOpened()) {
      return;
    }

    this.notificationStore.incrementNewNotification();
    this.notificationQueue.push(notification);
    this.tick();
    this.cdr.markForCheck();
  }

  closeNotification(index: number) {
    if (index < 0) {
      return;
    }

    this.displayedNotifications = [
      ...this.displayedNotifications.slice(0, index),
      ...this.displayedNotifications.slice(index + 1),
    ];
    this.cdr.markForCheck();
  }

  tick() {
    if (this.notificationStore.isPanelOpened()) {
      return;
    }

    if (this.displayedNotifications.length < this.MAX_DISPLAYED_NOTIFICATIONS) {
      // try get a new notification from queue
      const notification = this.notificationQueue.shift();
      if (notification) {
        // push the new notification on displayed notifications
        this.displayedNotifications.unshift(notification);

        // when max display time is passed, cleanup the notification
        setTimeout(() => {
          const index = this.displayedNotifications.indexOf(notification);
          this.closeNotification(index);
        }, this.MAX_DISPLAY_TIME);
      }
    }
  }

  openNotificationPanel() {
    this.clear();
    this.notificationStore.openPanel();
  }
}
